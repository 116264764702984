const MODES = {
  create: 'create',
  details: 'details',
  edit: 'edit',
};

const CURRENCY_TYPES = {
  JPY: 'JPY',
  USD: 'USD',
};

const PACKAGING_STATUS_TYPES = {
  before_packing: 'before_packing',
  after_packing: 'after_packing',
};

const PACKAGING_TYPES = {
  BA: 'BA - barrel',
  BE: 'BE - bundle',
  BG: 'BG - bag',
  BK: 'BK - basket,',
  BL: 'BL - bale, compressed',
  BN: 'BN - bale, non-compressed',
  BR: 'BR - bar',
  BX: 'BX - box',
  CA: 'CA - can, rectangular',
  CG: 'CG - cage',
  CH: 'CH - chest',
  CK: 'CK - cask',
  CL: 'CL - coil',
  CO: 'CO -carboy, non-protected',
  CP: 'CP - carboy, protected',
  CR: 'CR - crate',
  CS: 'CS - case',
  CT: 'CT - carton',
  CX: 'CX - can, cylindrical',
  CY: 'CY - cylinder',
  DP: 'DP - demijohn, protected',
  DR: 'DR - drum',
  FL: 'FL - flask',
  FR: 'FR - frame',
  HD: 'HD - head',
  HG: 'HG - hogshead',
  IN: 'IN - ingot',
  JR: 'JR - jar',
  JG: 'JG - jug',
  KG: 'KG - keg',
  LG: 'LG - log',
  LZ: 'LZ - logs, in bundle/bunch/truss',
  MT: 'MT - mat',
  NE: 'NE - unpacked or unpackaged',
  NT: 'NT - net',
  PA: 'PA - packet',
  PC: 'PC - parcel',
  PE: 'PE - pen',
  PG: 'PG - plate',
  PI: 'PK - pipe',
  PK: 'PK - package',
  PL: 'PL - pail',
  PP: 'PP - pallet',
  PS: 'PS - piece',
  PT: 'PT - pipes, in bundle/bunch/truss',
  PU: 'PU - tray',
  PY: 'PY - plates, in bundle/bunch/truss',
  PZ: 'PZ - planks, in bundle/bunch/truss',
  RL: 'RL - reel',
  RO: 'RO - roll',
  SA: 'SA - sack',
  SD: 'SD - skid',
  SE: 'SE - set',
  SK: 'SK - skeleton case',
  SL: 'SL - slab',
  ST: 'ST - sheet',
  SZ: 'SZ - sheet, in bundle/bunch/truss',
  TB: 'TB - tub',
  TI: 'TI - tierce',
  TN: 'TN - tin',
  VA: 'VA - vat',
  VG: 'VG - bulk, gas (atlo3lmbarandl5℃)',
  VL: 'VL - bulk, liquid',
  VO: 'VO - bulk, solid, large particles (nodules)',
  VP: 'VP - vanpack',
  VQ: 'VQ - bulk, liquefied gas (at abnormal temperature / pressure',
  VR: 'VR - bulk, solid, granular particles (grains)',
  VY: 'VY - bulk, solid, find particles (grains)',
  ZZ: 'ZZ - other',
};

export {
  MODES,
  CURRENCY_TYPES,
  PACKAGING_STATUS_TYPES,
  PACKAGING_TYPES,
};
