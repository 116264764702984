import '../../../shared/components/Topbar/Topbar.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { SidebarProvider } from '../../../shared/context/SidebarContext';

import SidebarContainer from '../Sidebar/SidebarContainer';

const propTypes = {
  authenticated: PropTypes.bool,
};

/**
 * This component is the placeholder of the app while loading the app(Suspense)
 * It renders only Sidebar and Top-bar
 */
const AppFallback = ({ authenticated }) => (
  authenticated && (
    <SidebarProvider>
      <SidebarContainer placeholder />
      <div className="topbar" />
    </SidebarProvider>
  )
);

AppFallback.propTypes = propTypes;
AppFallback.defaultProps = { authenticated: false };

export default AppFallback;
