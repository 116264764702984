const SELECTED_SIMULATION_EDIT = 'app/selectedSimulation/form/editSelectedSimulation';

// assignee
const ASSIGNEE_EDIT = 'app/selectedSimulation/form/editAssignee';

// schedule vessels and calendar
const SCHEDULE_METHOD_EDIT = 'app/selectedSimulation/form/editScheduleMethod';
const SCHEDULE_VESSEL_ID_EDIT = 'app/selectedSimulation/form/editScheduleVessel';
const SCHEDULE_CALENDAR_TYPE_EDIT = 'app/selectedSimulation/form/editScheduleCalendarType';
const SCHEDULE_CALENDAR_DATE_EDIT = 'app/selectedSimulation/form/editScheduleCalendarDate';

export {
  // eslint-disable-next-line import/prefer-default-export
  SELECTED_SIMULATION_EDIT,
  ASSIGNEE_EDIT,
  SCHEDULE_METHOD_EDIT,
  SCHEDULE_VESSEL_ID_EDIT,
  SCHEDULE_CALENDAR_TYPE_EDIT,
  SCHEDULE_CALENDAR_DATE_EDIT,
};
