import { combineReducers } from 'redux';
import addresses from '../../redux/addresses/reducers';
import apollo from '../../redux/apollo/reducers';
import chat from '../../shared/redux/chat/reducers';
import invoices from '../../redux/invoices/reducers';
import noticeList from '../../shared/redux/noticeList/reducers';
import places from './places/reducers';
import quoteForm from '../../redux/quoteRequestForm/reducers';
import selectedSimulation from '../../redux/selectedSimulation/reducers';
import shipments from '../../shared/redux/shipments/reducers';
import simulation from '../../redux/simulation/reducers';
import uiEvents from '../../shared/redux/uiEvents/reducers';
import user from '../../shared/redux/user/reducers';

// Root Reducer to pass into store
export default combineReducers({
  addresses,
  apollo,
  chat,
  invoices,
  noticeList,
  places,
  quoteForm,
  selectedSimulation,
  shipments,
  simulation,
  uiEvents,
  user,
});
