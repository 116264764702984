import * as types from './types';
import {
  SCHEDULE_METHOD,
  SCHEDULE_CALENDAR_DATE_TYPES,
} from '../../utils/constants/simulations';

const schedule = {
  method: SCHEDULE_METHOD.VESSEL,
  vesselId: null,
  calendar: {
    dateType: SCHEDULE_CALENDAR_DATE_TYPES.ETD,
    date: null,
  },
};

const initialSimulation = {
  selectedSimulation: null,
  assignee: null,
  schedule,
};

const simulation = (state = initialSimulation, action) => {
  switch (action.type) {
    case types.SELECTED_SIMULATION_EDIT: {
      const { selectedSimulation } = action.payload;

      return {
        ...state,
        selectedSimulation,
      };
    }

    case types.ASSIGNEE_EDIT: {
      const { assignee } = action.payload;
      return {
        ...state,
        assignee,
      };
    }

    case types.SCHEDULE_METHOD_EDIT: {
      const { method } = action.payload;
      return {
        ...state,
        schedule: {
          ...state.schedule,
          method,
        },
      };
    }

    // If vessel is selected the schedule method will be updated to calendar
    case types.SCHEDULE_VESSEL_ID_EDIT: {
      const { vesselId } = action.payload;
      return {
        ...state,
        schedule: {
          ...state.schedule,
          method: SCHEDULE_METHOD.VESSEL,
          vesselId,
        },
      };
    }

    case types.SCHEDULE_CALENDAR_TYPE_EDIT: {
      const { dateType } = action.payload;
      return {
        ...state,
        schedule: {
          ...state.schedule,
          calendar: {
            ...state.schedule.calendar,
            dateType,
          },
        },
      };
    }

    // If date is set the schedule method will be updated to calendar
    case types.SCHEDULE_CALENDAR_DATE_EDIT: {
      const { date } = action.payload;
      return {
        ...state,
        schedule: {
          ...state.schedule,
          method: SCHEDULE_METHOD.CALENDAR,
          calendar: {
            ...state.schedule.calendar,
            date,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default simulation;
