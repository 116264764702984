import React from 'react';
import { useTranslation } from 'react-i18next';

import SidebarMenuPlaceholder from '../../../../shared/components/Sidebar/SidebarMenu/SidebarMenuPlaceholder';

import { ReactComponent as ShipSolid } from '../../../../assets/icons/LineAwesome/ship-solid.svg';
import { ReactComponent as Paste } from '../../../../assets/icons/LineAwesome/paste-solid.svg';
import { ReactComponent as AddressBook } from '../../../../assets/icons/LineAwesome/address-book.svg';
import { ReactComponent as LuggageCartSolid } from '../../../../assets/icons/LineAwesome/luggage-cart-solid.svg';
import { ReactComponent as CogSolid } from '../../../../assets/icons/LineAwesome/cog-solid.svg';
import { ReactComponent as MapMarkedAltSolid } from '../../../../assets/icons/LineAwesome/map-marked-alt-solid.svg';
import { ReactComponent as InvoiceDollar } from '../../../../assets/icons/LineAwesome/file-invoice-dollar-solid.svg';
import { ReactComponent as Question } from '../../../../assets/icons/custom/question.svg';
import { ReactComponent as DashboardIcon } from '../../../../assets/icons/custom/dashboard-icon.svg';

const SidebarMenuPlaceholderContainer = () => {
  const { t } = useTranslation(['translation']);

  const menuItems = [
    // Menu items above the divider
    [
      {
        title: t('translation:navigation.dashboard'),
        icon: <DashboardIcon />,
      },
      {
        title: t('translation:navigation.shipments'),
        icon: <ShipSolid />,
      },
      {
        title: t('translation:navigation.quote'),
        icon: <Paste />,
      },
      {
        title: t('translation:navigation.invoices'),
        icon: <InvoiceDollar />,
      },
    ],
    // Menu items below the divider
    [
      {
        title: t('translation:navigation.productManagement'),
        icon: <LuggageCartSolid />,
      },
      {
        title: t('translation:navigation.customerManagement'),
        icon: <AddressBook />,
      },
      {
        title: t('translation:navigation.locationManagement'),
        icon: <MapMarkedAltSolid />,
      },
      {
        title: t('translation:navigation.setting'),
        icon: <CogSolid />,
      },
      {
        title: t('translation:navigation.support'),
        icon: <Question />,
      },
    ],
  ];

  return <SidebarMenuPlaceholder menuItems={menuItems} />;
};

export default SidebarMenuPlaceholderContainer;
