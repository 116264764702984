const ASSIGNABLE_CONTEXT_TYPES = {
  Shipment: 'Shipment',
  Task: 'Task',
  QuoteLane: 'QuoteLane',
};

const ASSIGNABLE_ENTITIES = {
  CustomerUser: 'CustomerUser',
  UserGroup: 'UserGroup',
};

export {
  ASSIGNABLE_ENTITIES,
  ASSIGNABLE_CONTEXT_TYPES,
};
