const INITIAL_STATE_RESET = 'app/simulation/form/resetInitialState';

const TRADE_DIRECTION_EDIT = 'app/simulation/form/editTradeDirection';
const TRANSPORTATION_MODE_EDIT = 'app/simulation/form/editTransportationMode';
const INCOTERM_EDIT = 'app/simulation/form/editIncoterm';
const CARGO_TYPE_EDIT = 'app/simulation/form/editCargoType';
const POL_EDIT = 'app/simulation/form/editPOL';
const POD_EDIT = 'app/simulation/form/editPOD';

// containers
const CONTAINER_TYPE_EDIT = 'app/simulation/form/editContainerType';
const CONTAINER_COUNT_EDIT = 'app/simulation/form/editContainerCount';
const CONTAINER_ADD = 'app/simulation/form/addContainer';
const CONTAINER_DELETE = 'app/simulation/form/deleteContainer';
// edit the whole array
const CONTAINERS_EDIT = 'app/simulation/form/editContainers';

// products
const PRODUCT_ADD = 'app/simulation/form/addProduct';
const PRODUCT_EDIT = 'app/simulation/form/editProduct';
const PRODUCT_DELETE = 'app/simulation/form/deleteProduct';
const PRODUCT_QUANTITY_EDIT = 'app/simulation/form/editQuantityProduct';
const REGISTERED_PRODUCT_DESELECT = 'app/simulation/form/deselectRegisteredProduct';
// edit the whole array
const PRODUCTS_EDIT = 'app/simulation/form/editProducts';

const MANUAL_INPUT_EDIT = 'app/simulation/form/editManualInput';
const TOTAL_VOLUME_EDIT = 'app/simulation/form/editTotalVolume';
const TOTAL_WEIGHT_EDIT = 'app/simulation/form/editTotalWeight';
const PACKAGING_STATUS_EDIT = 'app/simulation/form/editPackagingStatus';
// edit the whole object
const FULL_MANUAL_INPUT_EDIT = 'app/simulation/form/editFullManualInput';
const FULL_MANUAL_INPUT_RESET = 'app/simulation/form/resetFullManualInput';
// update slider values
const SLIDER_VOLUME_UPDATE = 'app/simulation/form/updateVolumeSlider';
const SLIDER_WEIGHT_UPDATE = 'app/simulation/form/updateWeightSlider';

const CARGO_DETAILS_SIMULATION_DISPLAY = 'app/simulation/form/displayCargoDetailsSimulation';

// place / drayage
const PLACE_DISABLE_EDIT = 'app/simulation/form/editDisablePlace';
const PLACE_RESERVATION_EDIT = 'app/simulation/form/editPlaceReservation';
const PLACE_ADD = 'app/simulation/form/addPlace';
const PLACE_EDIT_VEHICLE_TYPE = 'app/simulation/form/editPlaceVehicleType';
const PLACE_EDIT_QUANTITY = 'app/simulation/form/editPlaceQuantity';
const PLACE_EDIT_ADDRESS = 'app/simulation/form/editPlaceAddress';
const PLACE_DELETE = 'app/simulation/form/deletePlace';
// edit the whole array
const PLACES_EDIT = 'app/simulation/form/editPlaces';

// additional requests
const ADDITIONAL_REQUEST_EDIT = 'app/simulation/form/editAdditionalRequests';

// for different modes of simulation (default, shipmentRequest, confirmShipment)
const SIMULATION_MODE_EDIT = 'app/simulation/form/editSimulationMode';
// Temporary shipment id for confirm import
const SHIPMENT_ID_EDIT = 'app/simulation/form/editShipmentId';
// Keep track of missing values that may equate to throwing an error page
const SIMULATION_MISSING_VALUES = 'app/simulation/form/editSimulationMissingValues';

// Load saved quote
const SIMULATION_SET = 'app/simulation/form/setSimulation';

export {
  INITIAL_STATE_RESET,
  TRADE_DIRECTION_EDIT,
  TRANSPORTATION_MODE_EDIT,
  INCOTERM_EDIT,
  CARGO_TYPE_EDIT,
  POL_EDIT,
  POD_EDIT,
  CONTAINER_TYPE_EDIT,
  CONTAINER_COUNT_EDIT,
  CONTAINER_ADD,
  CONTAINER_DELETE,
  CONTAINERS_EDIT,
  PLACE_DISABLE_EDIT,
  PLACE_RESERVATION_EDIT,
  PLACE_ADD,
  PLACE_EDIT_VEHICLE_TYPE,
  PLACE_EDIT_QUANTITY,
  PLACE_EDIT_ADDRESS,
  PLACE_DELETE,
  PLACES_EDIT,
  ADDITIONAL_REQUEST_EDIT,
  PRODUCT_ADD,
  PRODUCT_DELETE,
  PRODUCT_EDIT,
  PRODUCT_QUANTITY_EDIT,
  REGISTERED_PRODUCT_DESELECT,
  PRODUCTS_EDIT,
  MANUAL_INPUT_EDIT,
  TOTAL_VOLUME_EDIT,
  TOTAL_WEIGHT_EDIT,
  PACKAGING_STATUS_EDIT,
  FULL_MANUAL_INPUT_EDIT,
  FULL_MANUAL_INPUT_RESET,
  SIMULATION_MODE_EDIT,
  SHIPMENT_ID_EDIT,
  SIMULATION_MISSING_VALUES,
  SIMULATION_SET,
  CARGO_DETAILS_SIMULATION_DISPLAY,
  SLIDER_VOLUME_UPDATE,
  SLIDER_WEIGHT_UPDATE,
};
