import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { ThemeProvider } from '@material-ui/core';
import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Rollbar from 'rollbar';

import { getCurrentLanguage } from 'shared/utils/utils';

import ErrorBoundary from '../components/ErrorBoundary';
import { useAuth } from '../shared/context/auth';
import frontTheme from '../shared/theme/frontTheme';
import { LANGUAGE_OPTIONS } from '../shared/utils/constants/settings';
import AppFallback from './components/AppFallback/AppFallback';

// Lazy load in applications
const AuthenticatedApp = React.lazy(() => import('./AppAuthenticated'));
const StaffApp = React.lazy(() => import('./AppAuthenticatedStaff'));

function App() {
  const { authenticated, isUserStaff } = useAuth();
  const { i18n } = useTranslation();
  const {
    isAuthenticated, isLoading, error, loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    if (['staging', 'production'].includes(process.env.REACT_APP_ENV)) {
      datadogRum.init({
        applicationId: 'a042aa2b-8333-4e36-8929-b8af311f405b',
        clientToken: 'pub32687bbecc95f08746ae17f6f8abdd98',
        site: 'datadoghq.com',
        service: 'front-office',
        env: process.env.REACT_APP_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
      datadogRum.startSessionReplayRecording();
    }
  }, []);

  const getApp = () => {
    if (isLoading) {
      // Without this 'isLoading' condition, it loops and returns to the login screen.
      return true;
    }

    if (isUserStaff() && authenticated) {
      return (<StaffApp />);
    }

    if (!isAuthenticated || error) {
      loginWithRedirect({ redirectUri: window.location.origin, ui_locales: getCurrentLanguage() });
      return null;
    }

    return (<AuthenticatedApp />);
  };

  useEffect(() => {
    window.rollbar = null;
    if (!window.rollbar) {
      // Rollbar config is here
      const rollbar = new Rollbar({
        accessToken: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        enabled: process.env.NODE_ENV === 'production',
        hostSafeList: [
          'app2.shippio.io',
          'staging.app2.shippio.io',
          'demo.app2.shippio.io',
          'app.shippio.jp',
          'app.stg.shippio.jp',
          'demo-app.shippio.jp',
        ],
        payload: {
          environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
        },
        checkIgnore: (_isUncaught, args) => {
          // Code here to determine whether or not to send the payload
          // to the Rollbar API
          // return true to ignore the payload
          if (args[1]?.name === 'ChunkLoadError') {
            return true;
          }

          return false;
        },
      });
      window.rollbar = rollbar;
    }

    return () => {
      window.rollbar = null;
    };
  }, []);

  useEffect(() => {
    const { language = LANGUAGE_OPTIONS.ja } = i18n;
    document.documentElement.lang = language;
  }, [i18n, i18n.language]);

  return (
    // This outer div is necessary to keep the notification popups in position
    <div>
      <ThemeProvider theme={frontTheme}>
        {/*
            Currently wrapping lazy loading routes with the test Error Boundary, although
            probably good to wrap the entire application with something like this
          */}
        <ErrorBoundary>
          <Suspense fallback={<AppFallback authenticated={authenticated} />}>
            {/*
                The idea here is lazy load the authenticated and unauthenticated logic,
                might have benefits on loading the initial app for logging in.
                It will also immediately switch to the unauthenticated app upon change in
                authenticated state from the auth context provider so less use of <Redirect>
              */}
            {getApp()}
          </Suspense>
        </ErrorBoundary>
      </ThemeProvider>
    </div>
  );
}

export default App;
