const MODES = {
  create: 'create',
  details: 'details',
  edit: 'edit',
};

const ADDRESS_TYPES = {
  customer_team_related: 'customer_team_related',
  trade_partner_related: 'trade_partner_related',
};

// Types for the query params
const ADDRESS_TYPES_URL = {
  c: {
    value: 'customer_team_related',
    searchParam: 'c',
  },
  p: {
    value: 'trade_partner_related',
    searchParam: 'p',
  },
};

const PLACE_FORM_ACTION_TYPES = {
  handlePlaceTypeChange: 'handlePlaceTypeChange',
  handleTradePartnerChange: 'handleTradePartnerChange',
  handleNameChange: 'handleNameChange',
  handleCountryCodeChange: 'handleCountryCodeChange',
  handlePostalCodeChange: 'handlePostalCodeChange',
  handleAddressChange: 'handleAddressChange',
  handleContactNameChange: 'handleContactNameChange',
  handleContactPhoneNumberChange: 'handleContactPhoneNumberChange',
  handleNotesChange: 'handleNotesChange',
  setQueriedPlace: 'setQueriedPlace',
};

export {
  MODES,
  ADDRESS_TYPES,
  ADDRESS_TYPES_URL,
  PLACE_FORM_ACTION_TYPES,
};
