/**
 * After reflection, having a big but easy to understand
 * object is the safest and the most maintainable option
 */
const ALL_AVAILABLE = {
  podId: false,
  polId: false,
  collectionPlaces: false,
  deliveryPlaces: false,
};

const ALL_OPTIONAL = {
  podId: false,
  polId: false,
  collectionPlaces: false,
  deliveryPlaces: false,
};

const IMPORT_D_SERIES = {
  disabled: {
    ...ALL_AVAILABLE,
    collectionPlaces: true,
  },
  required: {
    ...ALL_OPTIONAL,
  },
};

const PORTS_AND_PLACES_CONDITIONS = {
  import: {
    // EXW
    E: {
      checked: {
        disabled: ALL_AVAILABLE,
        required: {
          ...ALL_OPTIONAL,
          deliveryPlaces: true,
          collectionPlaces: true,
        },
      },
      unchecked: {
        disabled: {
          ...ALL_AVAILABLE,
          deliveryPlaces: true,
        },
        required: {
          ...ALL_OPTIONAL,
          podId: true,
          collectionPlaces: true,
        },
      },
    },
    // F series
    F: {
      checked: {
        disabled: {
          ...ALL_AVAILABLE,
          collectionPlaces: true,
        },
        required: {
          ...ALL_OPTIONAL,
          polId: true,
          deliveryPlaces: true,
        },
      },
      unchecked: {
        disabled: {
          ...ALL_AVAILABLE,
          deliveryPlaces: true,
          collectionPlaces: true,
        },
        required: {
          ...ALL_OPTIONAL,
          podId: true,
          polId: true,
        },
      },
    },
    // C series
    C: {
      checked: {
        disabled: {
          ...ALL_AVAILABLE,
          collectionPlaces: true,
        },
        required: {
          ...ALL_OPTIONAL,
          deliveryPlaces: true,
        },
      },
      unchecked: {
        disabled: {
          ...ALL_AVAILABLE,
          deliveryPlaces: true,
          collectionPlaces: true,
        },
        required: {
          ...ALL_OPTIONAL,
          podId: true,
        },
      },
    },
    // D series
    D: {
      checked: IMPORT_D_SERIES,
      unchecked: IMPORT_D_SERIES,
    },
  },
  export: {
    // D series
    D: {
      checked: {
        disabled: ALL_AVAILABLE,
        required: {
          ...ALL_OPTIONAL,
          deliveryPlaces: true,
          collectionPlaces: true,
        },
      },
      unchecked: {
        disabled: {
          ...ALL_AVAILABLE,
          collectionPlaces: true,
        },
        required: {
          ...ALL_OPTIONAL,
          polId: true,
          deliveryPlaces: true,
        },
      },
    },
    // C series
    C: {
      checked: {
        disabled: {
          ...ALL_AVAILABLE,
          deliveryPlaces: true,
        },
        required: {
          ...ALL_OPTIONAL,
          podId: true,
          collectionPlaces: true,
        },
      },
      unchecked: {
        disabled: {
          ...ALL_AVAILABLE,
          collectionPlaces: true,
          deliveryPlaces: true,
        },
        required: {
          ...ALL_OPTIONAL,
          podId: true,
          polId: true,
        },
      },
    },
    // F series
    F: {
      checked: {
        disabled: {
          ...ALL_AVAILABLE,
          deliveryPlaces: true,
        },
        required: {
          ...ALL_OPTIONAL,
          polId: true,
          collectionPlaces: true,
        },
      },
      unchecked: {
        disabled: {
          ...ALL_AVAILABLE,
          collectionPlaces: true,
          deliveryPlaces: true,
        },
        required: {
          ...ALL_OPTIONAL,
          polId: true,
        },
      },
    },
  },
};

export {
  PORTS_AND_PLACES_CONDITIONS,
};
