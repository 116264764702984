const baseType = 'app/quote/quoteRequestForm';

const RESET_FORM = `${baseType}/resetQuoteRequestForm`;
const SET_ADDITIONAL_REQUEST = `${baseType}/setAdditionalRequest`;

const TRADE_DIRECTION_EDIT = `${baseType}/editTradeDirection`;
const TRANSPORTATION_MODE_EDIT = `${baseType}/editTransportationMode`;
const INCOTERM_EDIT = `${baseType}/editIncoterm`;
const DRAYAGE_TRUCKING_REQUESTED = `${baseType}/editDrayageTruckingRequested`;
const EDIT_POL_ID = `${baseType}/editPolId`;
const EDIT_POD_ID = `${baseType}/editPodId`;
const EDIT_PLACE = `${baseType}/editPlace`;
const ADD_PLACE = `${baseType}/addPlace`;
const SET_PLACES = `${baseType}/setPlaces`;

const CARGO_TYPE_EDIT = `${baseType}/editCargoType`;
const CARGO_TITLE_EDIT = `${baseType}/editCargoTitle`;
const CONTAINER_TYPES_EDIT = `${baseType}/editContainerTypes`;
const REEFER_CONTAINER_INFO_EDIT = `${baseType}/editReeferContainerInfo`;
const DRAYAGE_TYPES_EDIT = `${baseType}/editDrayageTypes`;
const CONTAINS_DANGEROUS_GOODS_EDIT = `${baseType}/editContainsDangerousGoods`;
const IS_LONG_CARGO_EDIT = `${baseType}/editIsLongCargo`;
const IS_NON_STACKABLE_CARGO_EDIT = `${baseType}/editIsNonStackableCargo`;
const SPECIAL_CARGO_DETAILS_EDIT = `${baseType}/editSpecialCargoDetails`;
const SPECIAL_TRUCKING_REQUESTED_EDIT = `${baseType}/editSpecialTruckingRequested`;
const SPECIAL_TRUCK_REQUEST_DETAILS_EDIT = `${baseType}/editSpecialTruckRequestDetails`;

const INSURANCE_HANDLING_EDIT = `${baseType}/editInsuranceHandling`;
const CUSTOMS_HANDLING_EDIT = `${baseType}/editCustomsHandling`;
const REMARKS_EDIT = `${baseType}/editRemarks`;

const SET_STATE = `${baseType}/setState`;

export {
  RESET_FORM,
  TRADE_DIRECTION_EDIT,
  TRANSPORTATION_MODE_EDIT,
  INCOTERM_EDIT,
  DRAYAGE_TRUCKING_REQUESTED,
  CARGO_TYPE_EDIT,
  CARGO_TITLE_EDIT,
  CONTAINER_TYPES_EDIT,
  REEFER_CONTAINER_INFO_EDIT,
  DRAYAGE_TYPES_EDIT,
  CONTAINS_DANGEROUS_GOODS_EDIT,
  IS_LONG_CARGO_EDIT,
  IS_NON_STACKABLE_CARGO_EDIT,
  SPECIAL_CARGO_DETAILS_EDIT,
  SPECIAL_TRUCKING_REQUESTED_EDIT,
  SPECIAL_TRUCK_REQUEST_DETAILS_EDIT,
  INSURANCE_HANDLING_EDIT,
  CUSTOMS_HANDLING_EDIT,
  REMARKS_EDIT,
  EDIT_POL_ID,
  EDIT_POD_ID,
  EDIT_PLACE,
  ADD_PLACE,
  SET_ADDITIONAL_REQUEST,
  SET_PLACES,
  SET_STATE,
};
