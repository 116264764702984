import {
  INCOTERMS,
  DEFAULT_INCOTERM,
  QUOTE_REQUEST_TYPES,
  INITIAL_QUOTE_REQUEST_FORM_STATE,
  ADDITIONAL_QUOTE_REQUEST_TYPES,
  CONTAINER_TYPE_OPTIONS,
  DRAYAGE_TYPE_OPTIONS,
  INSURANCE_DISABLED_CONDITIONS,
} from '../constants/quote';

import { PORTS_AND_PLACES_CONDITIONS } from '../constants/quoteRequestPortsAndPlacesConditions';

import { parseObject } from '../index';

const tradeDirectionCascade = (newTradeDirection) => {
  const newAvailableIncoterms = INCOTERMS[newTradeDirection];
  const newIncoterm = DEFAULT_INCOTERM[newTradeDirection];
  return {
    availableIncoterms: newAvailableIncoterms,
    incoterm: newIncoterm,
  };
};

/** This should be called on change of either of the following
 *  - tradeDirection
 *  - incoterms
 *  - drayageTruckingRequested
 * @param state the state AFTER the update from the reducer
 */
const cascadeOnPortsAndPlaces = (state, cascadeValue = true) => {
  const { tradeDirection, incoterm, drayageTruckingRequested } = state;
  const series = incoterm.charAt(0);
  const requested = drayageTruckingRequested ? 'checked' : 'unchecked';
  const insuranceDisabled = INSURANCE_DISABLED_CONDITIONS[tradeDirection].includes(incoterm);
  if (PORTS_AND_PLACES_CONDITIONS?.[tradeDirection]?.[series]?.[requested]) {
    const { disabled, required } = PORTS_AND_PLACES_CONDITIONS[tradeDirection]?.
      [series]?.[requested];
    let newState = {
      disabled: {
        ...state.disabled,
        ...disabled,
        insuranceHandling: insuranceDisabled,
      },
      required: {
        ...state.required,
        ...required,
      },
      requestDetails: {
        ...state.requestDetails,
        collectionPlaces: disabled?.collectionPlaces ? [null]
          : state?.requestDetails?.collectionPlaces,
        deliveryPlaces: disabled?.deliveryPlaces ? [null] : state?.requestDetails?.deliveryPlaces,
        insuranceHandling: insuranceDisabled ? null : state?.requestDetails?.insuranceHandling,
      },
    };
    if (cascadeValue) {
      newState = {
        ...newState,
        podId: disabled?.podId ? null : state?.podId,
        polId: disabled?.polId ? null : state?.polId,
      };
    }
    return newState;
  }
  return {};
};

/**
 * Even if a field is supposed to be disabled, if he is required we need to enable it
 */
const cascadeForAdditionalRequest = (state) => {
  const {
    polId,
    podId,
    disabled,
    required,
    requestDetails,
    additionalRequest,
  } = state;
  // From the additional request specs
  const disabledFromAdditionalRequest = {
    ...disabled,
    tradeDirection: true,
    transportationMode: true,
    incoterm: true,
    drayageTruckingRequested: true,
    cargoType: true,
    cargoTitle: true,
    containsDangerousGoods: true,
    isLongCargo: true,
    isNonStackableCargo: true,
    specialCargoDetails: true,
    specialTruckingRequested: true,
    specialTruckRequestDetails: true,
    insuranceHandling: true,
    customsHandling: true,
    remarks: true,
    podId: !(additionalRequest === ADDITIONAL_QUOTE_REQUEST_TYPES.POD),
    polId: !(additionalRequest === ADDITIONAL_QUOTE_REQUEST_TYPES.POL),
    collectionPlaces: !(additionalRequest === ADDITIONAL_QUOTE_REQUEST_TYPES.collectionPlace
      || additionalRequest === ADDITIONAL_QUOTE_REQUEST_TYPES.POL),
    deliveryPlaces: !(additionalRequest === ADDITIONAL_QUOTE_REQUEST_TYPES.deliveryPlace
      || additionalRequest === ADDITIONAL_QUOTE_REQUEST_TYPES.POD),
    containerTypes: true,
    drayageTypes: !(additionalRequest === ADDITIONAL_QUOTE_REQUEST_TYPES.drayageType),
    reeferContainerInfo: true,
  };

  // If something is required but not existing, it needs to be enabled
  const dPlaceExist = requestDetails?.deliveryPlaces?.filter((p) => !!p).length > 0;
  const cPlaceExist = requestDetails?.collectionPlaces?.filter((p) => !!p).length > 0;

  // When places are required:
  // => If they are disabled, then they can only stay disabled
  //    IF there is existing data otherwise, otherwise they must be enabled
  // => If they are enabled then keep them enabled regardless
  const dPlaceDisabledIfRequired = disabledFromAdditionalRequest.deliveryPlaces
    ? dPlaceExist : false;
  const cPlaceDisabledIfRequired = disabledFromAdditionalRequest.collectionPlaces
    ? cPlaceExist : false;

  return {
    disabled: {
      ...disabledFromAdditionalRequest,
      polId: disabledFromAdditionalRequest.polId && !(required.polId && !polId),
      podId: disabledFromAdditionalRequest.podId && !(required.podId && !podId),
      // If places are required, then return the disabledIfRequired evaluation, otherwise if
      // not required nothing else matters and we use the disabledFromAdditionalRequest logic
      deliveryPlaces: required.deliveryPlaces
        ? dPlaceDisabledIfRequired : disabledFromAdditionalRequest.deliveryPlaces,
      collectionPlaces: required.collectionPlaces
        ? cPlaceDisabledIfRequired : disabledFromAdditionalRequest.collectionPlaces,
    },
  };
};

/**
 * To prevent human mistake to be made, especially with all the cascades
 */
const getInitialState = () => ({
  ...INITIAL_QUOTE_REQUEST_FORM_STATE,
  ...cascadeOnPortsAndPlaces(INITIAL_QUOTE_REQUEST_FORM_STATE),
});

/**
 * Check whether the given property should be considered empty
 *
 * At the moment this is only empty arrays and nulls
 * TODO: maybe we should include empty objects, but I don't see that in our form data as of yet.
 * @param property can be any type
 */
const isNotEmpty = (property) => {
  if (property === null) {
    return false;
  }

  if (typeof property === 'object') {
    if (property.constructor === Array) {
      if (property.length === 0) {
        return false;
      }
    }
  }

  return true;
};

/**
 * @param {string} field the field to validate
 * @param {bool | func} validationCondition the corresponding validation condition
 * @param {obj} data Flattened and parsed form data
 */
const validateField = (field, validationCondition, data) => {
  if (validationCondition === null) {
    return true;
  }
  if (validationCondition === true) {
    if (Array.isArray(data?.[field])) {
      return data?.[field].filter((e) => !!e).length > 0;
    }
    // Purely checking existence, values types i.e. enums should be restricted
    // by the field component.
    return !!data?.[field];
  }

  if (typeof validationCondition === 'function') {
    const subConditionsValidationPassed = validationCondition(data);

    // When null we don't care about sub condition evaluation, i.e.
    // it's not relevant
    if (subConditionsValidationPassed === null) {
      return true;
    }

    // When sub conditions validation has passed, then we need
    // to evaluate the presence of it.
    if (subConditionsValidationPassed) {
      // Purely checking existence, values types i.e. enums should be restricted
      // by the field component.
      return !!data?.[field];
    }

    // When sub conditions validation has failed, then we don't check presence
    // of current value and automatically fail the validation
    return false;
  }

  return false;
};

/**
 * Validate Quote Request form data
 * @param {obj} quoteFormData un-flattened and unparsed quote form data
 * @param {obj} FIELD_VALIDATION field validation rules
 * @return {bool}
 */
const validateQuoteRequestForm = (quoteFormData, FIELD_VALIDATION) => {
  const parsedFlattenedData = parseObject(quoteFormData, isNotEmpty, true);

  const fields = Object.keys(FIELD_VALIDATION);

  // Loop through over the fields that require validation
  for (let index = 0; index < fields.length; index++) {
    const field = fields[index];
    const condition = FIELD_VALIDATION[field];

    const validation = validateField(field, condition, parsedFlattenedData);

    // If the validation fails, then we need to stop and return false
    if (!validation) {
      return false;
    }
  }

  return true;
};

const formatAddresses = (addresses) => {
  if (addresses?.length) {
    return addresses.filter((a) => (!!a)).map((a) => ({
      addressId: a,
    }));
  }
  return [];
};

/**
 * Generate Quote Request form details arg for mutation
 * @param {obj} quoteFormData un-flattened and unparsed quote form data
 * @return {obj}
 */
const generateQuoteRequestDetails = (quoteFormData) => {
  const parsedData = parseObject(quoteFormData, isNotEmpty);
  // For now this will always be a New Quote Request until we add in extension requests
  parsedData.quoteRequestType = QUOTE_REQUEST_TYPES.new_quote_request;

  // Move drayage trucking requested to request details
  parsedData.requestDetails.drayageTruckingRequested = parsedData.drayageTruckingRequested;
  delete parsedData.drayageTruckingRequested;
  // we do not need transportation mode, this is a FE field
  delete parsedData.transportationMode;

  // Make sure we only pass valid drayage types
  if (parsedData.requestDetails?.drayageTypes) {
    parsedData.requestDetails.drayageTypes = parsedData.requestDetails.drayageTypes.filter(
      (d) => DRAYAGE_TYPE_OPTIONS.includes(d),
    );
  }

  // Make sure we only pass valid container types
  if (parsedData.requestDetails?.containerTypes) {
    parsedData.requestDetails.containerTypes = parsedData.requestDetails.containerTypes.filter(
      (c) => CONTAINER_TYPE_OPTIONS.includes(c),
    );
  }

  // addresses
  parsedData.requestDetails.collectionPlaces = formatAddresses(parsedData
    ?.requestDetails?.collectionPlaces);
  parsedData.requestDetails.deliveryPlaces = formatAddresses(parsedData
    ?.requestDetails?.deliveryPlaces);

  return parsedData;
};

/**
 *
 * @param {"AIR" or "OCEAN"} newTransportationMode
 * @param {object} state
 * @returns {object}
 */
const transportationModeCascade = (newTransportationMode, state) => ({
  cargoType: newTransportationMode === 'AIR' ? 'AIR' : 'FCL',
  podId: null,
  polId: null,
  requestDetails: newTransportationMode === 'AIR'
    // If "AIR", reset followings
    ? {
      ...state.requestDetails,
      containerTypes: [],
      reeferContainerInfo: null,
      drayageTypes: [],
      containsDangerousGoods: false,
      specialCargoDetails: null,
    // If "OCEAN", reset followings
    } : {
      ...state.requestDetails,
      isLongCargo: false,
      isNonStackableCargo: false,
      specialTruckingRequested: false,
      specialTruckRequestDetails: null,
      specialCargoDetails: null,
    },
});

/**
 *
 * @param {"AIR" or "OCEAN"} transportationMode
 * @param {object} state
 * @returns requestDetails
 */
const cargoTypeCascade = (cargoType, state) => ({
  // If "LCL" reset followings
  requestDetails: cargoType === 'LCL'
    ? {
      ...state.requestDetails,
      containerTypes: [],
      reeferContainerInfo: null,
      drayageTypes: [],
      specialCargoDetails: null,
      containsDangerousGoods: false,
    }
  // If "FCL" reset followings
    : {
      ...state.requestDetails,
      isLongCargo: false,
      isNonStackableCargo: false,
      specialTruckingRequested: false,
      specialTruckRequestDetails: null,
      specialCargoDetails: null,
    },
});

/**
 *
 * @param {array} containerTypes
 * @returns {bool}
 */
const isReeferSelected = (containerTypes) => containerTypes.some(
  (container) => container === '_20ft_reefer' || container === '_40ft_reefer',
);

export {
  cargoTypeCascade,
  validateQuoteRequestForm,
  generateQuoteRequestDetails,
  tradeDirectionCascade,
  transportationModeCascade,
  isReeferSelected,
  cascadeOnPortsAndPlaces,
  cascadeForAdditionalRequest,
  getInitialState,
};
