import * as types from './types';
import { getLocalStorageObject } from '../../utils/utils';
import { LAST_OPENED_AT_KEY } from '../../utils/constants/notices';

const storeLastOpenedAtValue = (customerTeamId) => {
  if (!localStorage || !customerTeamId) return;

  const lastOpenedAt = new Date().toISOString();
  const storageValue = getLocalStorageObject(LAST_OPENED_AT_KEY) || {};
  localStorage.setItem(
    LAST_OPENED_AT_KEY,
    JSON.stringify({
      ...storageValue,
      [customerTeamId]: lastOpenedAt,
    }),
  );
};

const initial = {
  isNoticeListOpen: false,
  unreadNoticeCount: 0,
};

const noticeList = (state = initial, action) => {
  switch (action.type) {
    case types.SET_NOTICE_LIST_OPEN: {
      const { customerTeamId, isNoticeListOpen } = action.payload;
      storeLastOpenedAtValue(customerTeamId);

      if (!isNoticeListOpen) {
        return {
          ...state,
          isNoticeListOpen,
        };
      }

      return {
        ...state,
        isNoticeListOpen,
        // zero-out count when list is opened
        unreadNoticeCount: 0,
      };
    }

    case types.SET_UNREAD_NOTICE_COUNT: {
      const { unreadNoticeCount } = action.payload;

      return {
        ...state,
        unreadNoticeCount,
      };
    }

    default:
      return state;
  }
};

export default noticeList;
