const NOTIFICATION_TYPE = {
  alert: 'alert',
  announcement: 'announcement',
};

const ALERT_STATUS_TYPE = {
  delay_critical: 'delay_critical',
  critical: 'critical',
  delay_warning: 'delay_warning',
  warning: 'warning',
};

const NOTIFICATION_CONTENT_TYPE = {
  alert: 'alert',
  eta_update: 'eta_update',
  car_contact_info: 'car_contact_info',
  milestone_complete: 'milestone_complete',
  bill_count: 'bill_count',
  quote_request_complete: 'quote_request_complete',
};

const NOTICE_LIST_PER_QUERY = 30;

const LAST_OPENED_AT_KEY = 'noticeListLastOpenedAt'; // Used for local storage

export {
  NOTIFICATION_TYPE,
  ALERT_STATUS_TYPE,
  NOTICE_LIST_PER_QUERY,
  NOTIFICATION_CONTENT_TYPE,
  LAST_OPENED_AT_KEY,
};
