const getTotalWeightAndVolume = (state, cargoType, isQuickMode) => {
  if (isQuickMode) {
    return state?.cargoType?.sliderValues?.[cargoType];
  }
  return state?.cargoType?.manualInput;
};

const getRecommendedTruck = (state, cargoType) => {
  if (!state?.vehicleManuallyChanged && state.tradeDirection === 'import') {
    // If sliders values exists
    if (state?.cargoType?.sliderValues?.[cargoType]) {
      const isQuickMode = !state?.cargoType?.isCargoDetailsSimulationOpen?.[cargoType];
      const { totalWeight, totalVolume } = getTotalWeightAndVolume(state, cargoType, isQuickMode);

      // When you have checked the "貨物の詳細設定を行う" checkbox, but have not entered any values.
      if (!isQuickMode && !totalVolume && !totalWeight) {
        return 'no_selection';
      }

      // Less than 2m3 & 100kg
      if (totalVolume < 2 && totalWeight < 100) {
        return 'consolidated';
      }
      // More than 2m3 & 100kg to less than 10m3 & 1800kg
      if (totalVolume < 10 && totalWeight < 1800) {
        return 'any_2t';
      }
      // More than 10m3 & 1800kg to less than 32m3 & 3500kg
      if (totalVolume < 32 && totalWeight < 3500) {
        return 'any_4t';
      }
      // More than 32m3 & 3500kg
      if (totalVolume >= 32 || totalWeight >= 3500) {
        return 'any_10t';
      }
    }
  }
  return null;
};

const cascadeForRecommendedTruck = (state, _cargoType) => {
  const cargoType = _cargoType || state?.cargoType?.activeCargoType;
  const recommendedTruck = getRecommendedTruck(state, cargoType);

  if (recommendedTruck) {
    const truckingPlaces = state?.places?.domestic?.trucking?.places;
    let newPlaces = [...truckingPlaces];

    if (truckingPlaces.length) {
      newPlaces = truckingPlaces.map((place) => ({
        ...place,
        type: recommendedTruck,
      }));
    }

    return {
      ...state,
      recommendedTruck,
      places: {
        ...state?.places,
        domestic: {
          ...state?.places?.domestic,
          trucking: {
            ...state?.places?.domestic?.trucking,
            places: newPlaces,
          },
        },
      },
    };
  }
  return state;
};

const cascadeOnTradeDirection = (state) => {
  // If new TD is Export
  if (state?.tradeDirection === 'export') {
    const places = state?.places?.domestic?.trucking?.places;
    if (places.length > 1 && places?.[0]) {
      return {
        ...state,
        places: {
          ...state?.places,
          domestic: {
            ...state?.places?.domestic,
            trucking: {
              ...state?.places?.domestic?.trucking,
              places: [places[0]],
            },
          },
        },
      };
    }
  }

  return state;
};

export {
  cascadeForRecommendedTruck,
  cascadeOnTradeDirection,
};
